import { useHistory, useParams } from 'react-router-dom';
import { Fragment, useMemo } from 'react';
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { MoreVert, People } from '@material-ui/icons';
import { Skeleton } from '@mui/material';
import { descend } from 'ramda';
import pluralize from 'pluralize';

import { GroupUserRequestStatus, SORT } from '@jebel/constants';
import { fileManyRelations, processFilestackUrlSrcSet } from '@jebel/utils';

import {
  useGroupDetails,
  useGroupMembers,
  useGroupPostCreate,
  useLeaveGroup,
} from 'features/groups/hooks';
import { useCurrentUser, useModalState, useQueryParams, useToast } from 'shared/hooks';
import {
  GroupPostCreateInput,
  GroupUpdateInput,
  useGroupMemberRequestUpsertMutation,
  useGroupUpdateMutation,
} from 'shared/graphql';
import { recordError } from 'shared/utils/record';
import { ROUTES } from 'shared/constants';
import { PostModalCreate, PostModalCreateForm } from 'shared/features/posts';
import { prepareMentionsCreateInput } from 'shared/features/mentions/utils';
import { Icon, Modal, Popover } from 'shared/components/ui';
import { SearchControl, SortFieldProps } from 'shared/features/search';

import {
  Container,
  Header,
  HeaderAvatar,
  HeaderContent,
  HeaderDescription,
  HeaderMembers,
  HeaderOptions,
  HeaderTitle,
} from './GroupDetailsHeader.styles';
import { GroupInviteModal } from '../GroupInviteModal';
import { GroupDetailsMembersModal } from '../GroupDetailsMembersModal';
import { GroupDetailsFormValues } from '../../GroupDetailsForm';
import { GroupDetailsFormModal } from '../../GroupDetailsFormModal';

const SORT_INFO: SortFieldProps = {
  sortInfo: {
    displayName: 'Sort by',
    fullWidth: true,
    options: [
      {
        label: 'Newest First',
        value: {
          createdAt: SORT.desc,
        },
      },
      {
        label: 'Oldest First',
        value: {
          createdAt: SORT.asc,
        },
      },
    ],
  },
};

interface Interaction {
  label: string;
  iconName?: string;

  onClick(): void;
}

const EDITING_MESSAGE = 'Saving the group, please stand by';
const EDITING_MESSAGE_KEY = 'GROUP_EDITING';

export function GroupDetailsHeader() {
  const [query] = useQueryParams();

  const { push: navigate } = useHistory();
  const { userId } = useCurrentUser();
  const { showError, showSuccess, showMessage, dismiss } = useToast();
  const { id } = useParams<{ id: string }>();
  const { action: leaveGroup } = useLeaveGroup({ group: { id } });
  const { data, loading: detailsLoading, refetch } = useGroupDetails({ id: id ?? '' });
  const { create: onPostCreate } = useGroupPostCreate(id);

  const [editGroup] = useGroupUpdateMutation({
    refetchQueries: ['GroupDetails', 'GroupList'],
  });

  const {
    show: showMembersModal,
    open: openMembersModal,
    close: closeMembersModal,
  } = useModalState({
    show: query.list === 'true',
  });

  const {
    show: showCreatePostModal,
    open: openCreatePostModal,
    close: closeCreatePostModal,
  } = useModalState();

  const { show: showEditModal, open: openEditModal, close: closeEditModal } = useModalState();
  const { show: showInviteModal, open: openInviteModal, close: closeInviteModal } = useModalState();

  const {
    data: members,
    isLoading: membersLoading,
    isCurrentUserMember,
    isCurrentUserPending,
    isCurrentUserAdmin,
  } = useGroupMembers(id);

  const title = data.title ?? '(Group)';
  const description = data.description ?? '';
  const avatarSource = data.logo?.downloadUrl ?? undefined;
  const avatarSourceSet = useMemo(() => processFilestackUrlSrcSet(avatarSource), [avatarSource]);
  const isGroupApproved = data?.isApproved ?? false;

  const loading = detailsLoading || membersLoading;
  const canPost = isGroupApproved && isCurrentUserMember && !loading;

  const approvedMembers = useMemo(() => {
    return members
      .filter(member => member.status === GroupUserRequestStatus.Approved)
      .sort(descend(member => member.isAdmin));
  }, [members]);

  const [updateGroup, { loading: joiningGroup }] = useGroupMemberRequestUpsertMutation({
    refetchQueries: ['GroupsList', 'GroupMembers'],
  });

  const onJoinGroup = async () => {
    try {
      await updateGroup({
        variables: {
          userId: userId as string,
          groupId: id as string,
          status: GroupUserRequestStatus.Pending,
        },
      });

      showSuccess(`Success! You successfully requested to join the group.`);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const interactions = useMemo<Interaction[]>(() => {
    const actions: Interaction[] = [];

    if (isCurrentUserMember) {
      actions.push({
        label: 'Invite Members',
        iconName: 'GroupAdd',
        onClick: openInviteModal,
      });
    }

    if (isCurrentUserAdmin) {
      actions.push({
        label: 'Members List',
        iconName: 'Groups',
        onClick: openMembersModal,
      });

      actions.push({
        label: 'Edit Information',
        iconName: 'Edit',
        onClick: openEditModal,
      });
    }

    if (isCurrentUserMember && !isCurrentUserAdmin) {
      actions.push({
        label: 'Leave Group',
        iconName: 'Logout',
        onClick: async () => {
          await leaveGroup();
          await refetch();
          return navigate(ROUTES.user.groups.index);
        },
      });
    }

    return actions;
  }, [
    isCurrentUserMember,
    isCurrentUserAdmin,
    openInviteModal,
    openMembersModal,
    leaveGroup,
    refetch,
    navigate,
  ]);

  const handleCreate = async (form: PostModalCreateForm) => {
    const payload: GroupPostCreateInput = {
      text: form.text ?? '',
      media: fileManyRelations(form.media),
      commentsAllowed: Boolean(form.commentsAllowed),
    };

    if (form.mentions) {
      const createMentions = form.mentions?.map(prepareMentionsCreateInput);
      payload.mentions = { create: createMentions };
    }

    await onPostCreate(payload);
    closeCreatePostModal();
  };

  const handleEdit = async (values: GroupDetailsFormValues) => {
    if (!data.id) {
      return;
    }

    try {
      const payload: GroupUpdateInput = {
        id: data.id,
        title: values.title,
        description: values.description,
      };

      if (values.logo) {
        payload.logo = {
          create: {
            fileId: values.logo.fileId,
            filename: values.logo.filename,
          },
        };
      }

      if (!values.logo && data.logo) {
        payload.logo = {
          disconnect: {
            fileId: data.logo?.fileId,
          },
        };
      }

      showMessage(EDITING_MESSAGE, { id: EDITING_MESSAGE_KEY });

      await editGroup({ variables: { data: payload } });

      showSuccess('Group information has been updated successfully');
      closeEditModal();
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    } finally {
      dismiss(EDITING_MESSAGE_KEY);
    }
  };

  if (loading) {
    return (
      <Container>
        <Header>
          <Skeleton variant="rectangular">
            <HeaderAvatar />
          </Skeleton>

          <HeaderContent>
            <Skeleton>
              <HeaderTitle>Lorem ipsum dolor sit</HeaderTitle>
            </Skeleton>

            <Skeleton>
              <HeaderMembers>Lorem, ipsum dolor.</HeaderMembers>
            </Skeleton>

            <Skeleton>
              <HeaderDescription>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              </HeaderDescription>
            </Skeleton>
          </HeaderContent>
        </Header>

        <Divider />

        <SearchControl
          withTextField
          withSortField
          sortFieldProps={SORT_INFO}
          textFieldProps={{ placeholder: 'Search posts' }}
        />
      </Container>
    );
  }

  return (
    <Fragment>
      <GroupDetailsMembersModal
        id={id}
        show={showMembersModal}
        onlyActive={!isCurrentUserMember}
        onClose={closeMembersModal}
      />

      <Modal
        isVisibleDivider
        titleProps={{ title: 'Invite Members' }}
        dialogProps={{
          open: showInviteModal,
          onClose: closeMembersModal,
          fullWidth: true,
        }}
      >
        <GroupInviteModal refetch={refetch} group={data} onModalClose={closeInviteModal} />
      </Modal>

      <GroupDetailsFormModal
        show={showEditModal}
        initials={data as GroupDetailsFormValues}
        onClose={closeEditModal}
        onSubmit={handleEdit}
      />

      <PostModalCreate
        isOpen={showCreatePostModal}
        onClose={closeCreatePostModal}
        onPostCreate={handleCreate}
      />

      <Container>
        <Header>
          <HeaderAvatar src={avatarSource} srcSet={avatarSourceSet}>
            <People />
          </HeaderAvatar>

          <HeaderContent>
            <HeaderTitle>{title}</HeaderTitle>

            <HeaderMembers onClick={openMembersModal}>
              {approvedMembers.length} {pluralize('member', approvedMembers.length)}
            </HeaderMembers>

            {description.split('\n').map(section => (
              <HeaderDescription key={section}>{section}</HeaderDescription>
            ))}
          </HeaderContent>

          <HeaderOptions>
            {isCurrentUserMember ? (
              <Button
                variant="contained"
                color="primary"
                onClick={openCreatePostModal}
                disableElevation
                disabled={!canPost}
              >
                Post
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={onJoinGroup}
                disableElevation
                disabled={joiningGroup}
              >
                {joiningGroup ? 'Joining...' : isCurrentUserPending ? 'Pending' : 'Join'}
              </Button>
            )}

            {isCurrentUserMember && (
              <Popover
                target={
                  <IconButton>
                    <MoreVert />
                  </IconButton>
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                elevation={4}
              >
                {popover => (
                  <List>
                    {interactions.filter(Boolean).map(interaction => (
                      <ListItem
                        key={interaction?.label}
                        button
                        onClick={() => {
                          interaction?.onClick();
                          popover.close();
                        }}
                      >
                        <ListItemIcon>
                          <Icon name={interaction?.iconName} variant="filled" />
                        </ListItemIcon>

                        <ListItemText>{interaction?.label}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Popover>
            )}
          </HeaderOptions>
        </Header>

        <Divider />

        <SearchControl
          withTextField
          withSortField
          sortFieldProps={SORT_INFO}
          textFieldProps={{ placeholder: 'Search posts' }}
        />
      </Container>
    </Fragment>
  );
}
