import { lazy, Suspense } from 'react';

import { FeedLayout } from 'shared/components/layouts';
import { PostFeedLoader } from 'shared/components/ui';

const HomeFeedContent = lazy(() => import('../components/HomeFeed/HomeFeedContent'));
const CommunitySidebar = lazy(
  () => import('shared/features/community-sidebar/CommunitySidebarDefaultExport'),
);

export function HomePage() {
  return (
    <FeedLayout
      sidebar={
        <Suspense fallback={<PostFeedLoader count={2} />}>
          <CommunitySidebar />
        </Suspense>
      }
    >
      <Suspense fallback={<PostFeedLoader />}>
        <HomeFeedContent />
      </Suspense>
    </FeedLayout>
  );
}
