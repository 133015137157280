import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';

// Container

export interface ContainerProps {
  /**
   * Size of the picker.
   * @default 'small'
   */
  size?: 'small' | 'medium' | 'large';

  /**
   * Variant of the avatar.
   * @default 'square'
   */
  variant?: 'square' | 'rounded' | 'circular';
}

const Container = styled.div<ContainerProps>`
  --media-field-gap: 0.5rem;
  --avatar-field-size: 3rem;

  display: flex;
  width: var(--avatar-field-size);
  height: var(--avatar-field-size);
  flex-direction: column;
  gap: var(--media-field-gap);

  ${p =>
    p.size === 'medium' &&
    css`
      --avatar-field-size: 8rem;
    `}

  ${p =>
    p.size === 'large' &&
    css`
      --avatar-field-size: 12rem;
    `}

  ${p =>
    p.variant === 'rounded' &&
    css`
      border-radius: 0.25rem;
    `}

  ${p =>
    p.variant === 'circular' &&
    css`
      border-radius: 50%;
    `}
`;

// PickerContainer

export const PickerContainer = styled(Container)`
  --media-field-preview-padding: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: var(--media-field-gap);
  padding: var(--media-field-preview-padding);
  border: dashed 1px;
  border-color: ${p => p.theme.palette.divider};
  user-select: none;
  cursor: pointer;
  color: ${p => p.theme.palette.text.secondary};

  &:hover {
    color: ${p => p.theme.palette.text.primary};
    border-color: ${p => p.theme.palette.action.active};
    background-color: ${p => p.theme.palette.action.hover};
  }
`;

// PickerIcon

export const PickerIcon = styled.div``;

// PickerLabel

export const PickerLabel = styled.span``;

// PreviewContainer

export const PreviewContainer = styled(Container)`
  cursor: pointer;
`;

// PreviewAvatar

export const PreviewAvatar = styled(Avatar)`
  width: var(--avatar-field-size);
  height: var(--avatar-field-size);
`;
