import React from 'react';
import _ from 'lodash';

import {
  useRoleAddonsQuery,
  useRoleAddonCreateMutation,
  useRoleAddonUpdateMutation,
} from 'shared/graphql';
import type { RoleAddonCreateInput, RoleAddonUpdateInput } from 'shared/graphql';

export function useRoleAddons() {
  const { data, loading: isLoading } = useRoleAddonsQuery();

  const [roleAddonCreateMutation, { loading: isCreating }] = useRoleAddonCreateMutation({
    refetchQueries: ['CurrentUser', 'RoleAddons'],
    awaitRefetchQueries: true,
  });

  const [roleAddonUpdateMutation, { loading: isUpdating }] = useRoleAddonUpdateMutation({
    refetchQueries: ['CurrentUser', 'RoleAddons'],
    awaitRefetchQueries: true,
  });

  const rolesAddons = React.useMemo(() => {
    return data?.roleAddonsList.items || [];
  }, [data?.roleAddonsList.items]);

  const createRoleAddon = React.useCallback(
    async (input: RoleAddonCreateInput) => {
      const { data } = await roleAddonCreateMutation({
        variables: {
          input,
        },
      });

      return data?.roleAddonCreate.id || null;
    },
    [roleAddonCreateMutation],
  );

  const updateRoleAddon = React.useCallback(
    async (roleId: string, input: RoleAddonUpdateInput) => {
      await roleAddonUpdateMutation({
        variables: {
          roleId,
          input,
        },
      });
    },
    [roleAddonUpdateMutation],
  );

  return {
    rolesAddons,
    createRoleAddon,
    updateRoleAddon,
    isLoading,
    isCreating,
    isUpdating,
  };
}
