import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Container

interface ContainerProps {
  borderless?: boolean;
}

export const Container = styled.div<ContainerProps>`
  --padding-vertical: 1.5rem;
  --padding-horizontal: var(--padding-vertical);

  --post-card-padding-horizontal: 0;
  --post-card-padding-vertical: 0;
  --post-card-padding: var(--post-card-padding-vertical) var(--post-card-padding-horizontal);
  --post-card-gap: 1rem;

  display: flex;
  // Preserve the aspect ratio of the card on grid layouts.
  // https://github.com/8base-services/jebel/issues/1433
  min-width: 0;
  flex-direction: column;
  gap: var(--post-card-gap);
  background-color: ${p => p.theme.palette.background.paper};
  padding: var(--padding-vertical) var(--padding-horizontal);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;

  ${p =>
    p.borderless &&
    css`
      --padding-horizontal: 0;

      --post-card-padding-horizontal: 1rem;
      --post-card-padding-vertical: 0;
      --post-card-gap: 1rem;

      box-shadow: none;
      border-radius: initial;
    `}

  ${p => p.theme.breakpoints.down('sm')} {
    --padding-vertical: 1rem;
  }
`;

// Content

export const Content = styled.div`
  padding: var(--post-card-padding);
`;

// Footer

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: var(--post-card-padding);
`;
