import { PropsWithChildren, createContext, useLayoutEffect, useState } from 'react';
import { useAuth } from '@8base-react/app-provider';
import { useApolloClient, gql } from '@apollo/client';
import { Amplify } from 'aws-amplify';
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  signIn,
  signInWithRedirect,
  signOut,
} from 'aws-amplify/auth';
import { CookieStorage, Hub } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

import {
  UserByEmailQuery,
  UserCompleteRegistrationInput,
  useUserCompleteRegistrationMutation,
  useUserSocialCompleteRegistrationMutation,
} from 'shared/graphql';
import { usePushNotifications, useToast } from 'shared/hooks';
import { AuthProviderName } from 'features/auth/types';
import { LOCAL_STORAGE_SIGNIN_ATTEMPTS, ROUTES } from 'shared/constants';
import { recordDebug, recordError, recordUser, stopRecordUser } from 'shared/utils/record';

const WORKSPACE_ID = process.env.REACT_APP_WORKSPACE_ID;
const WORKSPACE_ENV = process.env.REACT_APP_WORKSPACE_ENV;
const AUTH_POOL_ID = process.env.REACT_APP_AUTH_POOL_ID;
const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
const AUTH_STORAGE_DOMAIN = process.env.REACT_APP_AUTH_STORAGE_DOMAIN;

type UserChangePasswordInput = {
  resetCode: string;
  proposePassword: string;
  confirmProposePassword?: string;
  email: string;
};

const USER_BY_EMAIL_QUERY = gql`
  query UserByEmail($email: String!) {
    user(email: $email) {
      id
      email
    }
  }
`;

const USER_SEND_EMAIL_MUTATION = gql`
  mutation UserSendEmail($data: UserForgotPasswordEmailSendInput!) {
    userForgotPasswordEmailSend(data: $data) {
      success
    }
  }
`;

const AUTH_SIGNIN_REDIRECT_URL = `${window.location.origin}${ROUTES.public.login}`;
const AUTH_SIGNOUT_REDIRECT_URL = `${window.location.origin}`;

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: AUTH_POOL_ID,
      userPoolClientId: AUTH_CLIENT_ID,
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain: AUTH_DOMAIN,
          scopes: ['email', 'profile', 'openid'],
          redirectSignIn: [AUTH_SIGNIN_REDIRECT_URL],
          redirectSignOut: [AUTH_SIGNOUT_REDIRECT_URL],
          responseType: 'token',
        },
      },
    },
  },
});

// Setup the token provider to use cookies.
// https://docs.amplify.aws/react/build-a-backend/auth/concepts/tokens-and-credentials
cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({
    domain: AUTH_STORAGE_DOMAIN,
    secure: true,
  }),
);

const THRESHOLD_SIGNIN_ATTEMPTS = 2;

const MESSAGE_ALREADY_SESSION =
  'There is a session currently active, you cannot start a new one without sign-out';

function useAuthProvider() {
  const { authClient, isAuthorized } = useAuth();
  const { showMessage, showError, showSuccess, showWarning } = useToast();

  const [hasSession, setHasSession] = useState(() => authClient.checkIsAuthorized());
  const [registration] = useUserCompleteRegistrationMutation();
  const [socialRegistration] = useUserSocialCompleteRegistrationMutation();

  const apollo = useApolloClient();
  const pushNotifications = usePushNotifications();

  useLayoutEffect(() => {
    const unsubscribe = Hub.listen('auth', message => {
      const event = message.payload.event;

      if (event === 'signedIn' || event === 'signInWithRedirect') {
        setHasSession(true);

        showSuccess('Successfully signed-in');
        checkSession();
      }

      if (event === 'tokenRefresh') {
        recordDebug('Token was refresh');
      }

      if (event === 'signedOut') {
        stopRecordUser();
        setHasSession(false);
      }
    });

    // Check the session for the first time
    // Then, depends on subscriptions to the auth-flow
    checkSession();

    return unsubscribe;
  }, [isAuthorized]);

  /** Get the count of attempts of sign-in. */
  const getSignInAttempts = () => {
    const stored = localStorage.getItem(LOCAL_STORAGE_SIGNIN_ATTEMPTS);
    return Number(stored) || 0;
  };

  /** Increase by one the current attempts of sign-in. */
  const increaseSignInAttempts = () => {
    const attempts = getSignInAttempts();

    localStorage.setItem(LOCAL_STORAGE_SIGNIN_ATTEMPTS, String(attempts + 1));
    window.location.reload();
  };

  /**
   * Checks the current session, refresh the current token and verifies the user.
   */
  const checkSession = async () => {
    const attempts = getSignInAttempts();

    recordDebug(`Current sign-in attempts: ${attempts}`);

    if (attempts === THRESHOLD_SIGNIN_ATTEMPTS) {
      showError(`Too many sign-in attempts, please try again later`, { reportable: false });

      await logout();
      return;
    }

    try {
      const session = await fetchAuthSession();

      if (!session?.tokens) {
        recordDebug('No session was found on current session.');
        return;
      }

      const token = session.tokens.idToken?.toString();
      const payload = session.tokens.idToken?.payload;

      if (!token) {
        recordDebug(`No token was found on current session`);
        increaseSignInAttempts();

        return;
      }

      if (!payload) {
        recordDebug(`No payload was found on current session`);
        increaseSignInAttempts();

        return;
      }

      const email = String(payload?.email);
      const context = { headers: { authorization: `Bearer ${token}` } };

      recordDebug(`Session found: ${email}`);
      recordDebug(`Setting up token on GraphQL provider`);

      // Setup the token on the 8base auth provider
      // The token is now related to a user via `email`
      authClient.setState({
        token,
        email,
        workspaceId: WORKSPACE_ID,
        environmentName: WORKSPACE_ENV,
      });

      const response = await apollo.query<UserByEmailQuery>({
        query: USER_BY_EMAIL_QUERY,
        errorPolicy: 'ignore',
        context,
        variables: {
          email,
        },
      });

      if (!response.data.user?.id || !response.data.user?.email) {
        recordDebug(`No user was found with email: ${email}`);

        // Must be registered first before sign-in
        // Otherwise, the account does not match with any 8base user
        await logout();
        return;
      }

      await pushNotifications.setCurrentUser(response.data.user.id);
      recordUser(email);

      setHasSession(true);

      if (email) {
        // Checks whether the `email` is fully registered
        // Otherwise, will setup roles and permissions
        await socialRegistration({
          variables: { data: { email } },
        });
      }
    } catch (err) {
      recordError(err);
      increaseSignInAttempts();

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  /**
   * Sign-up a new user using `email` and `password`.
   * Creates an account on AWS Cognito and sign-in with the given token.
   */
  const register = async (data: UserCompleteRegistrationInput) => {
    if (hasSession) {
      showWarning(MESSAGE_ALREADY_SESSION);
      return;
    }

    try {
      const response = await registration({
        variables: { data },
      });

      const token = response?.data?.userCompleteRegistration?.idToken;

      if (!token) {
        showError(`No token found`, { reportable: false });
        return;
      }

      authClient.setState({
        token,
        email: data?.email,
        workspaceId: WORKSPACE_ID,
        environmentName: WORKSPACE_ENV,
      });

      setHasSession(true);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  /**
   * Sign-in using `email` and `password`.
   * Checks the credentials on AWS Cognito and start a new session with the given token.
   */
  const login = async (data: { email: string; password: string }) => {
    if (hasSession) {
      // Do not reload, will create a loop.
      showWarning(MESSAGE_ALREADY_SESSION);
      return;
    }

    try {
      await signIn({ username: data.email, password: data.password });
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    }
  };

  /**
   * Sign-out and purge the credential-related data.
   */
  const logout = async () => {
    setHasSession(false);

    try {
      await pushNotifications.resetCurrentUser();
      await signOut();

      authClient.purgeState();
    } finally {
      stopRecordUser();

      localStorage.clear();
      window.location.reload();
    }
  };

  /**
   * Sign-in using a redirect to the given `provider`.
   * To follow the authentication flow with a provider, when AWS Cognito return to the application use the {@link confirmSocialSignIn} function.
   */
  const socialSignIn = async (provider: AuthProviderName) => {
    if (hasSession) {
      // Do not reload, will create a loop.
      showWarning(MESSAGE_ALREADY_SESSION);
      return;
    }

    try {
      if (provider === AuthProviderName.Google) {
        await signInWithRedirect({ provider: 'Google' });
      }

      if (provider === AuthProviderName.Facebook) {
        await signInWithRedirect({ provider: 'Facebook' });
      }

      if (provider === AuthProviderName.LinkedIn) {
        await signInWithRedirect({ provider: { custom: 'LinkedIn' } });
      }
    } catch (err) {
      recordError(err);
      showError(`Something went wrong sign-in with "${provider}"`);
    }
  };

  /**
   * Sign-in using a provider but using {@link socialSignIn} first.
   * Use when AWS Cognito redirects to the application with the `code`.
   */
  const confirmSocialSignIn = async (code: string) => {
    if (hasSession) {
      return;
    }

    showMessage(`Checking sign-in confirmation code`);

    try {
      await confirmSignIn({ challengeResponse: code });
    } catch (err) {
      recordError(err);
    }
  };

  /**
   * Send an email to restore the password.
   */
  const forgotPassword = async (email: string): Promise<string | void> => {
    if (hasSession) {
      return;
    }

    try {
      const userQueryResponse = await apollo.query({
        query: USER_BY_EMAIL_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          email,
        },
      });

      if (!userQueryResponse.data.user) {
        showError('Submitted email was not found. Please try again.', { reportable: false });
        return;
      }

      await apollo.mutate({
        mutation: USER_SEND_EMAIL_MUTATION,
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            email,
          },
        },
      });

      const blurredEmail = `${email.slice(0, 2)}***@***${email.slice(email.lastIndexOf('.'))}`;

      return blurredEmail;
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  /**
   * Verify the `resetCode` and set the new `proposePassword` to the given `email`.
   */
  const changePassword = async ({ proposePassword, resetCode, email }: UserChangePasswordInput) => {
    if (hasSession) {
      return;
    }

    try {
      await confirmResetPassword({
        username: email,
        newPassword: proposePassword,
        confirmationCode: resetCode,
      });
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  return {
    /** @deprecated Use `hasSession` instead. */
    isAuthenticated: hasSession,
    hasSession,
    login,
    logout,
    register,
    socialSignIn,
    confirmSocialSignIn,
    forgotPassword,
    changePassword,
    checkSession,
  };
}

export type AuthContextType = ReturnType<typeof useAuthProvider>;

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider(props: PropsWithChildren<unknown>) {
  const context = useAuthProvider();

  return <AuthContext.Provider value={context}>{props.children}</AuthContext.Provider>;
}
