export const URL_REGEX = /^(?:(?<protocol>[^\s]+):\/\/)?(?<subdomain>(?:[a-zA-Z0-9]{1,}\.)+)*(?<domain>[a-zA-Z0-9]{1,})(?<port>:[0-9]+)?(?<extension>\.[a-zA-Z0-9]{2,}){1,3}(?<pathname>(?:\/[a-zA-Z0-9-_%&]+)+|\/)?(?<query>\?.+)?/i;
/** Check a given `value` as valid URL. */
export function isURL(value) {
    return URL_REGEX.test(value);
}
/**
 * Formats a given URL to ensure it starts with `http` or `https`.
 * If the URL does not start with `http`, `https://` is prepended to it.
 * @param url - The URL to be formatted.
 * @returns The formatted URL that starts with `http` or `https`.
 */
export function formatExternalLink(url) {
    if (!isURL(url)) {
        throw new SyntaxError(`"${url}" is not a URL`);
    }
    if (!url.startsWith('http')) {
        url = `https://${url}`;
    }
    return url;
}
