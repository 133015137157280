import { useMemo } from 'react';
import { ascend, descend, sortWith } from 'ramda';

import {
  AdItemFragment,
  AdvertisingCampaignAdFilter,
  useCampaignAdsListQuery,
} from 'shared/graphql';
import { useCurrentUser, useSchoolConfiguration } from 'shared/hooks';
import { formatMilliseconds } from 'shared/utils/date';

import {
  buildAdsFilter,
  buildAudiencesFilter,
  buildCampaignsFilter,
  haveNotReachSpendAmount,
  haveRightAudience,
} from '../utils/buildFilter';

const sortAdvertising = sortWith<AdItemFragment>([
  ascend(ad => ad.userViews?.count ?? 0),
  descend(ad => formatMilliseconds(ad.createdAt)),
]);

export function useCampaignAdsList() {
  const { user, loading: userLoading } = useCurrentUser();
  const { data: school } = useSchoolConfiguration();

  const variables = useMemo(() => {
    const adCampaignsFilter = buildCampaignsFilter();
    const adsFilter = buildAdsFilter();
    const audiences = buildAudiencesFilter({ user, school });

    const filter: AdvertisingCampaignAdFilter = {
      ...adsFilter,
      sampaign: adCampaignsFilter,
      OR: [
        { audiences: { some: audiences } },
        // Skip when there's no audiences selected
        { audiences: { none: null } },
      ],
    };

    return { filter };
  }, [user]);

  const { data: response, loading: responseLoading } = useCampaignAdsListQuery({
    skip: !user?.id,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    variables,
  });

  const data = useMemo(() => {
    const data = response?.advertisingCampaignAdsList?.items || [];

    const filtered = data
      .filter(haveNotReachSpendAmount)
      .filter(advertising => haveRightAudience(advertising, { user }));

    return sortAdvertising(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return {
    data,
    loading: responseLoading || userLoading,
  };
}
