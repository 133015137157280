import { PushPin, Schedule, History } from '@mui/icons-material';

import { useHomeFeedCardData } from 'shared/features/posts/providers';

import { PostCardIndicator } from '../../PostCardIndicator';

export function HomeFeedCardIndicator() {
  const data = useHomeFeedCardData();

  const isPinned = Boolean(data.isPinned);
  const isFuture = Boolean(data.isFuture);

  if (isPinned && isFuture) {
    return (
      <PostCardIndicator color="info">
        <History fontSize="small" color="inherit" />
        <PostCardIndicator.Text>Scheduled and Pinned</PostCardIndicator.Text>
      </PostCardIndicator>
    );
  }

  if (isPinned) {
    return (
      <PostCardIndicator color="secondary">
        <PushPin fontSize="small" color="inherit" />
        <PostCardIndicator.Text>Pinned Content</PostCardIndicator.Text>
      </PostCardIndicator>
    );
  }

  if (isFuture) {
    return (
      <PostCardIndicator color="info">
        <Schedule fontSize="small" color="inherit" />
        <PostCardIndicator.Text>Scheduled</PostCardIndicator.Text>
      </PostCardIndicator>
    );
  }

  return null;
}
