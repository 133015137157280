import { ReactNode } from 'react';
import { Centered, Container, Content, Sidebar } from './FeedLayout.styles';
import { useResponsive } from 'shared/hooks';

export interface FeedLayoutProps {
  children: ReactNode;

  sidebar?: ReactNode;
}

/**
 * Layout for a content-sidebar arrangement for feed-related pages.
 */
export function FeedLayout(props: FeedLayoutProps) {
  const { isMobile } = useResponsive();

  return (
    <Container>
      <Centered>
        <Content>{props.children}</Content>
      </Centered>

      {!isMobile && <Sidebar>{props.sidebar}</Sidebar>}
    </Container>
  );
}
