import { gql } from '@apollo/client';

import { FILE_FRAGMENT } from './file';
import { JOB_OFFER_PRESENTATION_FRAGMENT } from './jobOffers';

export const MINIMAL_USER_FRAGMENT = gql`
  fragment MinimalUser on User {
    id
    firstName
    lastName
    email
    avatar {
      ...File
    }
  }

  ${FILE_FRAGMENT}
`;

export const OWNED_ORGANIZATION_FRAGMENT = gql`
  fragment OwnedOrganization on Organization {
    id
    name
    description

    advertising {
      id
      supporter {
        id
        isSupporter
      }
    }

    logo {
      id
      fileId
      downloadUrl
    }
  }
`;

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUser on User {
    id
    email
    firstName
    lastName
    fullName
    status
    userStatus
    affiliation
    affiliationDescription
    activityClubs
    description
    gender
    graduatingYear
    hobbies
    isSeekingEmployment
    birthDate
    parents
    currentIndustry
    professionalDesignations

    groupAdminPreferences {
      id
    }

    avatar {
      ...File
    }

    managerPreferences {
      items {
        id
        status
        isAdmin
      }
    }

    joinedGroups {
      items {
        id
        title
        membersCount
        status
        isApproved
        logo {
          ...File
        }
      }
    }

    viewedThreads {
      items {
        id
      }
    }

    createdGroups {
      items {
        id
        title
        membersCount
        logo {
          ...File
        }
      }
    }

    ownedOrganizations {
      items {
        ...OwnedOrganization
      }
    }

    userPreferences {
      id
      phoneNumber
      secondaryPhoneNumber
      address {
        id
        city
        state
        street1
        zip
      }
    }

    schoolConfigurations {
      items {
        id
      }
    }

    education {
      items {
        id

        highSchool {
          id
        }
      }
    }
  }

  ${FILE_FRAGMENT}
  ${OWNED_ORGANIZATION_FRAGMENT}
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    user {
      ...CurrentUser
    }
  }

  ${CURRENT_USER_FRAGMENT}
`;

export const CURRENT_USER_SAVED_JOB_OFFERS_QUERY = gql`
  query CurrentUserSavedJobOffers($filter: JobOfferFilter) {
    user {
      preferences: userPreferences {
        saved: jobsOfInterest(filter: $filter) {
          items {
            ...JobOfferPresentation
          }
        }
      }
    }
  }

  ${JOB_OFFER_PRESENTATION_FRAGMENT}
`;

export const USER_DETAILED_FRAGMENT = gql`
  fragment UserDetailed on User {
    id
    email
    firstName
    lastName
    fullName
    status
    userStatus
    affiliation
    affiliationDescription
    activityClubs
    description
    gender
    graduatingYear
    hobbies
    isSeekingEmployment
    birthDate
    parents
    currentIndustry
    professionalDesignations

    avatar {
      ...File
    }

    roles {
      items {
        id
      }
    }

    rolesAddons {
      items {
        id

        school {
          id
        }
      }
    }

    userPreferences {
      id
      phoneNumber
      secondaryPhoneNumber
      address {
        id
        city
        state
        street1
        zip
      }
    }

    schoolConfigurations {
      items {
        id
      }
    }
  }

  ${FILE_FRAGMENT}
`;

export const USER_BY_ID_QUERY = gql`
  query UserById($id: ID) {
    user(id: $id) {
      ...UserDetailed
    }
  }

  ${USER_DETAILED_FRAGMENT}
`;

export const USER_UPDATE_MUTATION = gql`
  mutation UserUpdate($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      ...CurrentUser
    }
  }

  ${CURRENT_USER_FRAGMENT}
`;

export const USER_DELETE_MUTATION = gql`
  mutation UserDelete($data: UserDeleteInput!) {
    userDelete(data: $data) {
      success
    }
  }
`;

export const USERS_LIST_COUNT_QUERY = gql`
  query UsersListCount($filter: UserFilter) {
    usersList(filter: $filter) {
      count
    }
  }
`;

export const USERS_LIST_QUERY = gql`
  query UsersList($filter: UserFilter, $first: Int, $skip: Int, $sort: [UserSort!]) {
    usersList(filter: $filter, first: $first, skip: $skip, sort: $sort) {
      count
      items {
        ...CurrentUser
      }
    }
  }

  ${CURRENT_USER_FRAGMENT}
`;

export const MINIMAL_USERS_LIST_QUERY = gql`
  query MinimalUsersList($filter: UserFilter, $skip: Int, $first: Int, $sort: [UserSort!]) {
    users: usersList(filter: $filter, sort: $sort, skip: $skip, first: $first) {
      count
      items {
        ...MinimalUser
      }
    }
  }

  ${MINIMAL_USER_FRAGMENT}
`;

export const COMMENT_AUTHORS_QUERY = gql`
  query CommentAuthor($id: ID!) {
    user(id: $id) {
      ...MinimalUser
    }
  }
  ${MINIMAL_USER_FRAGMENT}
`;

export const SEND_USERS_INVITE_MUTATION = gql`
  mutation SendUserInvites(
    $users: [UserRequestAccessInput!]!
    $isAdminInviting: Boolean
    $withAdminRole: Boolean
    $withBusinessAdminRole: Boolean
  ) {
    sendUserInvites(
      users: $users
      isAdminInviting: $isAdminInviting
      withAdminRole: $withAdminRole
      withBusinessAdminRole: $withBusinessAdminRole
    ) {
      success
    }
  }
`;

export const REJECT_REQUEST_MUTATION = gql`
  mutation RejectRequest($id: ID!) {
    adminRejectRequest(usersIds: [$id]) {
      success
    }
  }
`;

export const RESET_USER_CREDENTIALS = gql`
  mutation ResetUserCredentials($user: UserKeyFilter!) {
    resetUserCredentials(user: $user) {
      success
    }
  }
`;
