import styled from '@emotion/styled';
import { CarnivalLayout } from '../CarnivalLayout';

// Container

export const Container = styled(CarnivalLayout)`
  ${p => p.theme.breakpoints.down('sm')} {
    --carnival-layout-padding-horizontal: 0;
  }
`;

// Centered

export const Centered = styled.div`
  min-height: 100vh;
`;

// Content

export const Content = styled(CarnivalLayout.Content)`
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
`;

// Sidebar

export const Sidebar = styled(CarnivalLayout.Sidebar)``;
