import { Box } from '@material-ui/core';
import { useRef, useState } from 'react';
import { css, Theme } from '@emotion/react';
import { Formik } from 'formik';

import { Button, Icon, Typography } from 'shared/components/ui';
import { MinimalUserFragment, UserRequestAccessInput } from 'shared/graphql';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { useToast, useUserInvitation, useUserInvitationCSV } from 'shared/hooks';
import { useBusinessSearch } from 'admin-features/business/hooks';
import { sendToSentry } from 'shared/utils/sentry';
import { FormSelectUser } from 'shared/components/form';

interface BusinessManagersInviteModalProps {
  onModalClose: () => void;
  onRequestSuccess?: () => void;
  additionalData?: { businessId: string };
}

const BOTTOM_BUTTONS_PADDING = 20;
const DROP_ZONE_HEIGHT = 150;
const DROP_ZONE_ICON_SIZE = 50;

const FILE_TYPE = '.csv';

const ERROR_GENERIC_MESSAGE = 'Something goes wrong when creating the user, please try again.';

const commonButtonsCSS = css`
  padding: ${BOTTOM_BUTTONS_PADDING / 5}px ${BOTTOM_BUTTONS_PADDING}px;
  margin-left: ${BOTTOM_BUTTONS_PADDING}px;
`;

const cancelButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = css`
  ${commonButtonsCSS};
`;

const dropZoneContainer = (theme: Theme) => css`
  background-color: ${theme.palette.background.light};
  border: 1px dashed #bbbbbb;
  height: ${DROP_ZONE_HEIGHT}px;
  display: grid;
  grid-template-rows: min-content min-content;
  align-items: center;
  justify-items: center;
  align-content: center;
  cursor: pointer;
`;

const dropZoneIconCss = (theme: Theme) => css`
  font-size: 14px;
  fill: ${theme.palette.primary.light};
  width: ${DROP_ZONE_ICON_SIZE}px;
  height: ${DROP_ZONE_ICON_SIZE}px;
`;

const dropZoneTextCss = (theme: Theme) => css`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.palette.primary.light};
`;

interface Values {
  managers: (string | MinimalUserFragment)[];
}

const INITIAL_VALUES: Values = {
  managers: [],
};

export function BusinessManagersInviteModal({
  onModalClose,
  onRequestSuccess,
  additionalData,
}: BusinessManagersInviteModalProps) {
  const [emails, setEmails] = useState<string[]>([]);
  const [alertText, setAlertText] = useState<string | undefined | null>(undefined);

  const { showError } = useToast();
  const { loading } = useBusinessSearch(additionalData?.businessId ?? '');

  const { handle: onUploadFileChange } = useUserInvitationCSV({
    onComplete(parsed) {
      const emails = parsed.map(user => user.email);
      setEmails(prev => prev.concat(emails));
    },
  });

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const setCleanEmails = (emails: string[]) => {
    const clean = new Set(emails);

    setEmails(Array.from(clean));
  };

  const onUploadFile = () => {
    hiddenFileInput?.current?.click();
  };

  const { inviteBusinessManagers, isInviting: managerInvitingLoading } = useUserInvitation({
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]:
        alertText || 'Success! Your invitations have been sent. Thank you.',
      [SNACKBAR_ERROR_MESSAGE]: "Error! Your invitations haven't been sent",
    },
  });

  const onSubmit = async (values: Values) => {
    if (!additionalData?.businessId) {
      showError('No Business provided');

      onModalClose();
      return;
    }

    if (!values?.managers || values.managers.length === 0) {
      showError('You haven`t provided needed info to invite person. Review it carefully.');

      onModalClose();
      return;
    }

    try {
      const invitations = emails.map<UserRequestAccessInput>(email => {
        return {
          firstName: 'Business',
          lastName: 'User',
          affiliation: 'other',
          affiliationDescription: 'business user',
          email,
        };
      });

      await inviteBusinessManagers(invitations, additionalData.businessId);

      onRequestSuccess?.();
      onModalClose();
    } catch (err) {
      sendToSentry(err);

      if (err instanceof Error) {
        showError(err.message);

        return;
      }

      showError(ERROR_GENERIC_MESSAGE);
    }

    setAlertText(undefined);
  };

  const handleChangeManager = (managers: (string | MinimalUserFragment)[]) => {
    const emails = managers.map(manager =>
      typeof manager === 'string' ? manager : (manager.email as string),
    );

    setCleanEmails(emails);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={INITIAL_VALUES}>
      {form => (
        <Box display="grid">
          <Box mb={1}>
            <Typography variant="subtitle5" color="textSecondary">
              Invite existing users by typing their name or non-existing users by entering their
              email address.
            </Typography>
          </Box>

          <FormSelectUser
            multiple
            value={emails}
            allowUnknown="allow"
            name="managers"
            variant="outlined"
            label="Invite Managers"
            placeholder="example@domain.com"
            onChange={handleChangeManager}
          />

          <Typography variant="body1" color="primary">
            Invite businesses by uploading a list
          </Typography>

          <Box css={dropZoneContainer} onClick={onUploadFile}>
            <Icon css={dropZoneIconCss} name="CloudUpload" variant="filled" />

            <Typography css={dropZoneTextCss} variant="inherit">
              Import a list (CSV file)
            </Typography>

            <input
              type="file"
              accept={FILE_TYPE}
              style={{ display: 'none' }}
              onChange={onUploadFileChange}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              css={cancelButtonCSS}
              onClick={onModalClose}
              disabled={managerInvitingLoading}
              size="medium"
            >
              CANCEL
            </Button>

            <Button
              loading={managerInvitingLoading || loading}
              css={submitButtonCSS}
              disableElevation
              variant="contained"
              type="submit"
              color="primary"
              onClick={form.submitForm}
            >
              SEND INVITATIONS
            </Button>
          </Box>
        </Box>
      )}
    </Formik>
  );
}
