import { Skeleton } from '@mui/material';

import { DiscountPresentationContent } from 'shared/components/ui';
import { useModalState } from 'shared/hooks';
import { ReedemDiscountModal } from 'features/discounts/components/ReedemDiscountModal';

import { Container } from './HomeFeedCardDiscount.styles';
import { useHomeFeedDiscount } from '../useHomeFeedDiscount';
import { PostCard } from '../../PostCard';

export function HomeFeedCardDiscount() {
  const { data: discount, has: hasDiscount, loading } = useHomeFeedDiscount();
  const { show: showRedeemModal, open: openRedeemModal, close: closeRedeemModal } = useModalState();

  if (hasDiscount && loading) {
    return (
      <PostCard.Content>
        <Container>
          <Skeleton variant="rectangular" width="100%" height="10rem" />
        </Container>
      </PostCard.Content>
    );
  }

  if (!discount) {
    return null;
  }

  return (
    <PostCard.Content>
      <Container>
        <DiscountPresentationContent discount={discount} withRedeem onRedeem={openRedeemModal} />

        {showRedeemModal && (
          <ReedemDiscountModal
            isOpen={showRedeemModal}
            onClose={closeRedeemModal}
            discount={discount ?? undefined}
          />
        )}
      </Container>
    </PostCard.Content>
  );
}
