import { useAuth } from '@8base-react/auth';

import { SchoolConfigurationFragment, useSchoolConfigurationsQuery } from 'shared/graphql';

/**
 * Hook for fetching school configurations.
 * @ticket https://github.com/jebelapp/jebel/issues/1562
 */
export function useSchoolConfigurations() {
  const { isAuthorized } = useAuth();

  const { data: response, loading } = useSchoolConfigurationsQuery({
    skip: !isAuthorized,
  });

  const schools: SchoolConfigurationFragment[] = response?.schools?.items ?? [];

  return {
    data: schools,
    loading,
  };
}
