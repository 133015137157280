import { createFilterBuilder } from '@jebel/utils';

import {
  AdItemFragment,
  AdvertisingCampaignAdFilter,
  AdvertisingSampaignFilter,
  AudienceFilter,
  CurrentUserFragment,
  Maybe,
  SchoolConfigurationFragment,
} from 'shared/graphql';
import { extractYear, formatAgeYears } from 'shared/utils/date';

export function buildCampaignsFilter(): AdvertisingSampaignFilter {
  const now = new Date().toISOString();

  const filter = createFilterBuilder<AdvertisingSampaignFilter>({
    status: { equals: 'active' },
  });

  filter.or(
    { type: { equals: 'ongoing' } },
    { type: { equals: 'scheduled' }, startDate: { lte: now }, endDate: { gt: now } },
  );

  return filter.build();
}

export function buildAdsFilter(): AdvertisingCampaignAdFilter {
  const dateNow = new Date();

  const filter = createFilterBuilder<AdvertisingCampaignAdFilter>({
    status: { equals: 'active' },
  });

  const minutes = dateNow.getMinutes();
  const weekDay = dateNow.toLocaleString('en', { weekday: 'long' }).toLowerCase();

  filter.or({ runConstantly: { equals: true } });

  filter.or({
    [`${weekDay}Enabled`]: { equals: true },
    [`${weekDay}StartDate`]: { lte: minutes },
    [`${weekDay}EndDate`]: { gt: minutes },
  });

  return filter.build();
}

export function buildAudiencesFilter(context: {
  user?: Maybe<CurrentUserFragment>;
  school?: Maybe<SchoolConfigurationFragment>;
}): AudienceFilter {
  const filter = createFilterBuilder<AudienceFilter>();

  filter.and({
    OR: [
      { gender: { equals: context.user?.gender } },
      { gender: { equals: 'all' } },
      { gender: { is_empty: true } },
    ],
  });

  if (context.user?.birthDate) {
    const age = formatAgeYears(context.user.birthDate);

    filter.and({
      startAge: { lte: age },
      OR: [{ endAge: { is_empty: true } }, { endAge: { gt: age } }],
    });
  }

  if (context.user?.education?.items) {
    const educations = context.user.education.items ?? [];

    const identifiers = educations
      .map(education => education.highSchool?.id)
      // Prevents to put an empty high-school before migration
      .filter((id): id is string => typeof id === 'string');

    filter.and({
      OR: [
        { highSchools: { some: { id: { in: identifiers } } } },
        // Skip when there's no high schools selected
        { highSchools: { none: null } },
      ],
    });
  }

  if (context.school) {
    filter.and({
      OR: [
        // Filter by the selected school.
        // https://github.com/jebelapp/jebel/issues/1562#issuecomment-2353364597
        { schoolCommunities: { some: { id: { equals: context.school.id } } } },
        // Skip when there's no high schools selected
        { schoolCommunities: { none: null } },
      ],
    });
  }

  return filter.build();
}

/**
 * Finish the filter by audience.
 * Used to filter on the client side because of a issue with 8base.
 * https://8base-dev.atlassian.net/browse/JEB-1431?focusedCommentId=42554
 */
export const haveRightAudience = (
  ad: AdItemFragment,
  context: {
    user?: Maybe<CurrentUserFragment>;
  },
): boolean => {
  const audiences = ad.audiences?.items ?? [];

  const industry = context.user?.currentIndustry;
  const affiliation = context.user?.affiliation;
  const graduatingYear = extractYear(context.user?.graduatingYear);
  const zipCode = context.user?.userPreferences?.address?.zip;
  const city = context.user?.userPreferences?.address?.city;
  const state = context.user?.userPreferences?.address?.state;
  const hobbies = context.user?.hobbies ?? [];
  const clubs = context.user?.activityClubs ?? [];

  if (audiences.length === 0) {
    // Bypass the audience because have not objective.
    return true;
  }

  return audiences.some(audience => {
    const audienceIndustry = audience.industry ?? [];
    const audienceAffiliation = audience.affiliation ?? [];
    const audienceGraduatingYear = audience.graduatingYear ?? [];
    const audienceZipCode = audience.zipCode ?? [];
    const audienceCity = audience.city ?? [];
    const audienceState = audience.state ?? [];
    const audienceHobbies = audience.hobbies ?? [];
    const audienceClubs = audience.clubs ?? [];

    const checks: [unknown[], unknown][] = [
      [audienceIndustry, industry],
      [audienceAffiliation, affiliation],
      [audienceGraduatingYear, graduatingYear],
      [audienceZipCode, zipCode],
      [audienceCity, city],
      [audienceState, state],
      [audienceHobbies, hobbies],
      [audienceClubs, clubs],
    ];

    return checks.every(check => {
      const [expected, received] = check;

      if (expected.length === 0) {
        // Leave the array of possibilities empty means all the values are accepted.
        return true;
      }

      if (Array.isArray(received)) {
        // For array-array comparation we use `includes` to match on both.
        return expected.some(curr => received.includes(curr));
      }

      return expected.includes(received);
    });
  });
};

export const haveNotReachSpendAmount = (ad: AdItemFragment) => {
  let isReachedTotalLimit = false;
  let isReachedDailyLimit = false;

  if (ad.totalBudgetEnabled) {
    isReachedTotalLimit = Number(ad.totalSpend) >= Number(ad.totalBudget);
  }

  if (ad.dailyBudgetEnabled) {
    isReachedDailyLimit = Number(ad.todaySpend) >= Number(ad.dailyBudget);
  }

  return !isReachedDailyLimit && !isReachedTotalLimit;
};
