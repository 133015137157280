import styled from '@emotion/styled';
import { Dialog as DialogBase, IconButton, PaperProps } from '@material-ui/core';
import { ComponentType } from 'react';

// Paper

const Paper = styled.div<PaperProps>`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  user-select: none;
`;

// Dialog

export const Dialog = styled(DialogBase)``;

Dialog.defaultProps = {
  PaperComponent: Paper as ComponentType<PaperProps>,
};

// Header

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  color: white;
`;

// CarouselContainer

export const CarouselContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  overflow: hidden;

  ${p => p.theme.breakpoints.down('md')} {
    gap: 0.5rem;
  }
`;

// CarouselContent

export const CarouselContent = styled.div`
  min-height: 80vh;
  align-self: stretch;
  flex: 1;
`;

// CarouselButtons

export const CarouselButtons = styled.div``;

// CarouselButton

export const CarouselButton = styled(IconButton)`
  color: white;

  ${p => p.theme.breakpoints.down('md')} {
    padding: 0.5rem;
    font-size: 1.5rem;
  }
`;
