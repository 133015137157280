import { AvatarGroup, Skeleton } from '@mui/material';

import { GROUP_POST_TYPE, LEGACY_POST_TYPE, SCHOOL_POST_TYPE } from '@jebel/constants';

import { useHomeFeedCardData } from 'shared/features/posts/providers';
import { useGroupDetails } from 'features/groups/hooks';
import {
  GroupAvatar,
  GroupNameLink,
  SchoolAvatar,
  SchoolNameLink,
  UserAvatar,
  UserNameLink,
} from 'shared/components/symbols';

import { GroupDetailsContainer } from './HomeFeedCardAuthor.styles';
import { useHomeFeedAuthor } from '../useHomeFeedAuthor';
import { useHomeFeedCreationAgo } from '../useHomeFeedCreationAgo';
import { PostCardAuthor } from '../../PostCardAuthor';

export function HomeFeedCardAuthor() {
  const { type, groupId } = useHomeFeedCardData();
  const { data: author, loading: userLoading } = useHomeFeedAuthor();

  /**
   * Checks whether the current post is authored to the school.
   * @url https://8base-dev.atlassian.net/browse/JEB-1534
   */
  const isSchoolAuthored = type === SCHOOL_POST_TYPE || type === LEGACY_POST_TYPE;

  /**
   * Checks whether the current post is authored to the school.
   * @url https://8base-dev.atlassian.net/browse/JEB-1534
   */
  const isGroupPost = type === GROUP_POST_TYPE;

  const { data: group, loading: groupLoading } = useGroupDetails({
    id: groupId,
    skip: !isGroupPost,
  });

  const loading = userLoading || groupLoading;

  const creationDate = useHomeFeedCreationAgo();

  if (loading) {
    return (
      <PostCardAuthor.Header>
        <PostCardAuthor.HeaderAvatarSkeleton />

        <PostCardAuthor.HeaderContent>
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="4rem" />
        </PostCardAuthor.HeaderContent>
      </PostCardAuthor.Header>
    );
  }

  if (type === GROUP_POST_TYPE) {
    return (
      <PostCardAuthor.Header>
        <AvatarGroup max={1}>
          <GroupAvatar group={group} />
          <UserAvatar user={author} />
        </AvatarGroup>

        <PostCardAuthor.HeaderContent>
          <PostCardAuthor.HeaderName>
            <GroupNameLink group={group} />
          </PostCardAuthor.HeaderName>

          <GroupDetailsContainer>
            <PostCardAuthor.HeaderName>
              <UserNameLink user={author} />
            </PostCardAuthor.HeaderName>

            <PostCardAuthor.CreationDate>{creationDate}</PostCardAuthor.CreationDate>
          </GroupDetailsContainer>
        </PostCardAuthor.HeaderContent>
      </PostCardAuthor.Header>
    );
  }

  return (
    <PostCardAuthor.Header>
      {isSchoolAuthored ? <SchoolAvatar /> : <UserAvatar user={author} />}

      <PostCardAuthor.HeaderContent>
        <PostCardAuthor.HeaderName>
          {isSchoolAuthored ? <SchoolNameLink /> : <UserNameLink user={author} />}
        </PostCardAuthor.HeaderName>

        <PostCardAuthor.CreationDate>{creationDate}</PostCardAuthor.CreationDate>
      </PostCardAuthor.HeaderContent>
    </PostCardAuthor.Header>
  );
}
