import { Formik } from 'formik';
import { GroupDetailsForm, GroupDetailsFormValues } from './GroupDetailsForm';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';

interface Props {
  show: boolean;
  initials?: GroupDetailsFormValues;

  onSubmit(values: GroupDetailsFormValues): void;
  onClose?(): void;
}

const DEFAULT_INITIALS: GroupDetailsFormValues = {
  title: '',
  description: '',
  logo: undefined,
};

export function GroupDetailsFormModal(props: Props) {
  return (
    <Formik initialValues={props.initials ?? DEFAULT_INITIALS} onSubmit={props.onSubmit}>
      {form => (
        <Dialog open={props.show} maxWidth="sm" fullWidth onClose={props.onClose}>
          <DialogContent dividers>
            <GroupDetailsForm />
          </DialogContent>

          <DialogActions>
            <Button disabled={form.isSubmitting} onClick={props.onClose}>
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={form.isSubmitting}
              disableElevation
              onClick={form.submitForm}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
