import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  --carnival-layout-gap: 2rem;
  --carnival-layout-padding-vertical: 2rem;
  --carnival-layout-padding-horizontal: 2rem;

  --carnival-layout-padding: var(--carnival-layout-padding-vertical)
    var(--carnival-layout-padding-horizontal);

  display: grid;
  grid-template-columns: 1fr 20rem;
  gap: var(--carnival-layout-gap);
  padding: var(--carnival-layout-padding);
  background-color: ${p => p.theme.palette.background.light};

  ${p => p.theme.breakpoints.down('sm')} {
    --carnival-layout-padding-vertical: 1rem;
    --carnival-layout-padding-horizontal: 1rem;

    grid-template-columns: 1fr;
  }
`;

// Content

export const Content = styled.div``;

// Sidebar

export const Sidebar = styled.div``;
