import {
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributes,
  ReactNode,
  RefAttributes,
} from 'react';

import { Container, Content, Footer } from './PostCard.styles';

export interface PostCardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;

  /**
   * Remove the border of the card.
   * @ticket https://github.com/jebelapp/jebel/issues/1525
   * @default false
   */
  borderless?: boolean;
}

export type PostCardComponent = ForwardRefExoticComponent<
  PostCardProps & RefAttributes<HTMLDivElement>
> & {
  Content: typeof Content;
  Footer: typeof Footer;
};

/**
 * Container for a post card.
 * @ticket https://github.com/jebelapp/jebel/issues/1525
 */
export const PostCard = forwardRef<HTMLDivElement, PostCardProps>((props, ref) => {
  return (
    <Container ref={ref} {...props}>
      {props.children}
    </Container>
  );
}) as PostCardComponent;

/**
 * Content of a post card.
 */
PostCard.Content = Content;

/**
 * Footer of a post card.
 */
PostCard.Footer = Footer;
