import { ChangeEvent, useState } from 'react';
import * as yup from 'yup';

import { formatSQLDate, readCSV, isNumber } from '@jebel/utils';
import { USER_AFFILIATIONS } from '@jebel/constants';

import { useToast } from './useToast';
import { recordError } from 'shared/utils/record';

const CSV_EXTENSION = '.csv';
const SINGLE_YEAR_REGEX = /^[0-9]+$/;

enum Header {
  AFFILIATION = 'Affiliation',
  DESCRIPTION = 'Affiliation Description',
  EMAIL = 'Email',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  PHONE_NUMBER = 'Phone Number',
  GRADUATION_YEAR = 'Graduation Year',
}

export interface UserInvitationParsed {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  affiliation: string;
  affiliationDescription?: string;
  graduatingYearIso?: string;
}

const USER_INVITATION_PARSED_SCHEMA = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),

  affiliation: yup
    .string()
    .oneOf(Object.values(USER_AFFILIATIONS))
    .required()
    .transform(value => value.toLowerCase()),

  affiliationDescription: yup.string(),
  graduatingYearIso: yup.string(),
  phoneNumber: yup.string(),
});

interface Options {
  /**
   * Handles the start of the CSV parsing.
   */
  onStart?(): void;

  /**
   * Handles the completion of the CSV parsing.
   * @param parsed The parsed user invitations.
   */
  onComplete?(parsed: UserInvitationParsed[]): void;
}

/**
 * Custom hook for reading and parsing a CSV file containing user invitations.
 * @param options - Optional configuration options.
 * @returns An object with the following properties:
 *   - `reading`: A boolean indicating if the CSV file is being read.
 *   - `handle`: A function to handle the file change event.
 */
export function useUserInvitationCSV(options?: Options) {
  const [reading, setReading] = useState(false);

  const { showError } = useToast();

  return {
    /**
     * Indicates if the CSV file is being read.
     */
    reading,

    /**
     * Handles the {@linkcode HTMLInputElement} change event.
     * @param event The file change event.
     */
    async handle(event: ChangeEvent<HTMLInputElement>): Promise<void> {
      const file = event.target.files?.[0];

      if (!file) {
        return;
      }

      if (!file.name?.endsWith(CSV_EXTENSION)) {
        showError('Please check the file type. Only CSV files are allowed.');
        return;
      }

      setReading(true);
      options?.onStart?.();

      try {
        const text = await file.text();

        const content = readCSV<Record<string, string>>(text, {
          hasHeaders: true,
          skipEmpty: true,
        });

        const invitations: UserInvitationParsed[] = [];

        for (const item of content) {
          const invitation = USER_INVITATION_PARSED_SCHEMA.validateSync({
            firstName: item[Header.FIRST_NAME],
            lastName: item[Header.LAST_NAME],
            email: item[Header.EMAIL],
            affiliation: item[Header.AFFILIATION],
            affiliationDescription: item[Header.DESCRIPTION],
            graduatingYearIso: item[Header.GRADUATION_YEAR],
            phoneNumber: item[Header.PHONE_NUMBER],
          });

          if (
            invitation.affiliation === USER_AFFILIATIONS.alumni &&
            !invitation.graduatingYearIso
          ) {
            throw new Error(`Missing graduation year for email: ${invitation.email}`);
          }

          if (
            invitation.affiliation === USER_AFFILIATIONS.other &&
            !invitation.affiliationDescription
          ) {
            throw new Error(`Missing affiliation description for email: ${invitation.email}`);
          }

          if (invitation.graduatingYearIso) {
            if (
              SINGLE_YEAR_REGEX.test(invitation.graduatingYearIso) ||
              isNumber(invitation.graduatingYearIso)
            ) {
              // Transform from single year to SQL format.
              const date = new Date(Number(invitation.graduatingYearIso), 1, 1);
              invitation.graduatingYearIso = formatSQLDate(date);
            } else {
              // Transform to SQL format.
              invitation.graduatingYearIso = formatSQLDate(invitation.graduatingYearIso);
            }
          }

          invitations.push(invitation);
        }

        options?.onComplete?.(invitations);
      } catch (err) {
        recordError(err);

        if (err instanceof Error) {
          showError(err.message);
        }
      } finally {
        setReading(false);
      }
    },
  };
}
