import { ReactNode } from 'react';
import {
  Actions,
  Container,
  CreationDate,
  Header,
  HeaderAvatar,
  HeaderAvatarSkeleton,
  HeaderContent,
  HeaderName,
} from './PostCardAuthor.styles';

export interface PostCardAuthorProps {
  children: ReactNode;
}

/**
 * Layout for a post card author.
 * @ticket https://github.com/jebelapp/jebel/issues/1525
 */
export function PostCardAuthor(props: PostCardAuthorProps) {
  return <Container>{props.children}</Container>;
}

/**
 * Container of the author's content and avatar.
 */
PostCardAuthor.Header = Header;

/**
 * Avatar of the author's.
 */
PostCardAuthor.HeaderAvatar = HeaderAvatar;

/**
 * Skeleton of the author's avatar.
 */
PostCardAuthor.HeaderAvatarSkeleton = HeaderAvatarSkeleton;

/**
 * Container of the author's content like name and {@link PostCardAuthor.CreationDate}.
 */
PostCardAuthor.HeaderContent = HeaderContent;

/**
 * Wraps the name of the author.
 */
PostCardAuthor.HeaderName = HeaderName;

/**
 * Container of the author's actions.
 */
PostCardAuthor.Actions = Actions;

/**
 * Container of the creation date.
 */
PostCardAuthor.CreationDate = CreationDate;
