import { useField } from 'formik';
import { Fragment, ReactNode } from 'react';
import { PickerOptions } from 'filestack-js';
import { FileInput, FileInputValue, FileValue } from '@8base-react/file-input';
import { ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@material-ui/core';
import { AttachFile, CloudUpload, DeleteOutline } from '@material-ui/icons';

import {
  ContainerProps,
  PickerContainer,
  PickerIcon,
  PickerLabel,
  PreviewAvatar,
  PreviewContainer,
} from './AvatarField.styles';
import { usePopoverState } from 'shared/hooks';

export interface AvatarFieldProps extends ContainerProps {
  name: string;

  label?: string;

  /**
   * Icon to display in the picker.
   */
  pickerIcon?: ReactNode;

  /**
   * Restricts the types of files that can be selected.
   * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   * @default undefined
   * @example 'image/*'
   * @example ['image/*', 'application/pdf']
   * @example ['image/*', 'application/pdf', 'video/*']
   * @example 'image/png'
   */
  accept?: string | string[];
}

type Picker = (options?: PickerOptions) => void;

export function AvatarField(props: AvatarFieldProps) {
  const {
    show: showPreviewOptions,
    anchor: previewOptionsAnchor,
    open: openPreviewOptions,
    close: closePreviewOptions,
  } = usePopoverState();

  const [field, , helpers] = useField<FileValue | undefined | null>({
    name: props.name,
  });

  const handlePickerClick = (pick: (options?: PickerOptions) => void) => () => {
    closePreviewOptions();

    pick({
      accept: props.accept,
      maxFiles: 1,
      fromSources: ['local_file_system'],
    });
  };

  const handleUploadDone = async (curr: FileInputValue) => {
    const file = Array.isArray(curr) ? curr[0] : curr;

    helpers.setValue(file);
    return curr;
  };

  const handleRemove = () => {
    helpers.setValue(null);
    closePreviewOptions();
  };

  if (field.value) {
    return (
      <Fragment>
        <PreviewContainer onClick={openPreviewOptions} size={props.size} variant={props.variant}>
          <PreviewAvatar
            title={props.name}
            src={field.value.downloadUrl}
            variant={props.variant ?? 'square'}
          />
        </PreviewContainer>

        <Menu
          open={showPreviewOptions}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          getContentAnchorEl={null}
          anchorEl={previewOptionsAnchor}
          onClose={closePreviewOptions}
        >
          <FileInput onUploadDone={handleUploadDone}>
            {params => (
              <MenuItem onClick={handlePickerClick(params.pick as Picker)}>
                <ListItemIcon>
                  <AttachFile />
                </ListItemIcon>

                <ListItemText>Upload another</ListItemText>
              </MenuItem>
            )}
          </FileInput>

          <MenuItem onClick={handleRemove}>
            <ListItemIcon>
              <DeleteOutline />
            </ListItemIcon>

            <ListItemText>Remove</ListItemText>
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }

  return (
    <FileInput onUploadDone={handleUploadDone}>
      {params => (
        <PickerContainer
          size={props.size}
          variant={props.variant}
          onClick={handlePickerClick(params.pick as Picker)}
        >
          <PickerIcon>{props.pickerIcon ?? <CloudUpload />}</PickerIcon>

          {props.label && <PickerLabel>{props.label}</PickerLabel>}
        </PickerContainer>
      )}
    </FileInput>
  );
}
