import { useAuth } from '@8base-react/auth';

import { RoleAddonFragment, useCurrentUserRoleAddonsQuery } from 'shared/graphql';

import { useSchoolConfiguration } from './useSchoolConfiguration';
import { useMemo } from 'react';

/**
 * Custom hook that retrieves the current user's role addons.
 * @returns An object containing the current user's role addons.
 */
export function useCurrentUserRoleAddons() {
  const { isAuthorized } = useAuth();
  const { data: school, loading: schoolLoading } = useSchoolConfiguration();

  const { data: response, loading: rolesLoading } = useCurrentUserRoleAddonsQuery({
    skip: !school?.id || !isAuthorized,
    variables: {
      school: school?.id as string,
    },
  });

  const roles: RoleAddonFragment[] = useMemo(() => response?.rolesAddons?.items ?? [], [response]);

  return {
    data: roles,
    loading: rolesLoading || schoolLoading,
  };
}
