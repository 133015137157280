import { ChangeEvent, Fragment, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { BoxProps, Box, Paper, TableContainer, Table, TableBody } from '@mui/material';
import { Divider } from '@material-ui/core';

import {
  HeadlinesType,
  SpreadsheetDataType,
  SpreadsheetCellActions,
  SpreadsheetFiltersType,
  MainToolbarActionType,
  useSpreadsheetContext,
  SpreadsheetRow,
  SpreadsheetToolbar,
  SpreadsheetHeader,
  SpreadsheetPagination,
} from 'shared/features/spreadsheet';

import { SpreadsheetPreloader } from './SpreadsheetPreloader';
import { SpreadsheetEmpty } from './SpreadsheetEmpty';

const tableFooterCss = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const dividerCss = (theme: any) => css`
  background-color: ${theme.palette.border};
  margin-bottom: ${theme.spacing(5)}px;
`;

export type SpreadsheetProps<T extends HeadlinesType> = {
  headlines: T;
  data: SpreadsheetDataType<T>[];
  cellActions?: SpreadsheetCellActions;
  containerProps?: BoxProps;
  itemsCount: number;
  loading?: boolean;
  toolbarOptions?: {
    mainToolbarAction?: MainToolbarActionType;
    filters?: SpreadsheetFiltersType;
    withDownload?: boolean;
    withSearch?: boolean;
    withPerPage?: boolean;
    withIndex?: boolean;
    downloadHandler?: (rawData?: any) => void;
    rawData?: any;
    withUpload?: boolean;
    withPDFDownload?: boolean;
    /** @default true */
    withCheckboxes?: boolean;
    downloadPDFHandler?: (rawData?: any, text?: string) => void;

    /**
     * Use to handle the file upload change event from a {@linkcode HTMLInputElement} element.
     */
    onUpload?(event: ChangeEvent<HTMLInputElement>): void;
  };
  toolbarHeader?: JSX.Element;
  toolbarHeaderWidth?: string;
  textToolbarHeader?: string;
};

/**
 * Use useSpreadsheetContext with this component
 * Use useSpreadsheetSearch for search
 * All headlines fields must be in data
 * In data fields 'id' and 'createdAt' is required
 */

export const Spreadsheet = <T extends HeadlinesType>({
  headlines,
  data,
  cellActions,
  containerProps,
  loading,
  toolbarOptions,
  itemsCount,
  toolbarHeader,
  toolbarHeaderWidth,
  textToolbarHeader,
}: SpreadsheetProps<T>) => {
  const { page, pageSize, selected, setSelected } = useSpreadsheetContext();

  const withCheckboxes = toolbarOptions?.withCheckboxes ?? true;

  const onSelect = useCallback(
    (id: string) => () => {
      setSelected(currentSelected => {
        const isSelected = currentSelected.includes(id);
        if (isSelected) {
          return currentSelected.filter(selectedId => selectedId !== id);
        }

        return [...currentSelected, id];
      });
    },
    [setSelected],
  );

  const onSelectAll = useCallback(() => {
    setSelected(currentSelected => {
      const dataIds = data.map(row => row.id);
      if (currentSelected.some(id => dataIds.includes(id))) {
        return currentSelected.filter(id => !dataIds.includes(id));
      }

      return [...currentSelected, ...data.map(row => row.id)];
    });
  }, [data, setSelected]);

  const onClickMainAction = useCallback(() => {
    const onClick = toolbarOptions?.mainToolbarAction?.onClick;

    onClick && onClick(selected);
  }, [selected, toolbarOptions]);

  const tableContent = useMemo(() => {
    const columnsCount = headlines.length + (cellActions?.length === 0 ? 1 : 2);

    if (loading) {
      return <SpreadsheetPreloader columnsCount={columnsCount} />;
    }

    if (data.length === 0) {
      return <SpreadsheetEmpty columnsCount={columnsCount} />;
    }

    return (
      <Fragment>
        {data.map((row, index) => {
          const isSelected = selected.some(selectedId => selectedId === row.id);
          return (
            <SpreadsheetRow
              withCheckbox={withCheckboxes}
              rowData={row}
              cellActions={cellActions}
              headlines={headlines}
              key={row.id}
              isSelected={isSelected}
              onSelect={onSelect(row.id)}
              withIndex={!!toolbarOptions?.withIndex}
              index={index + (page - 1) * pageSize}
              howManySelected={selected.length}
            />
          );
        })}
      </Fragment>
    );
  }, [
    cellActions,
    data,
    headlines,
    loading,
    onSelect,
    page,
    pageSize,
    selected,
    withCheckboxes,
    toolbarOptions?.withIndex,
  ]);

  return (
    <Box width="100%" {...containerProps}>
      <Paper>
        <TableContainer>
          <SpreadsheetToolbar
            toolbarOptions={toolbarOptions}
            onSelectAll={onSelectAll}
            isRowsSelected={!!selected.some(id => data.some(row => row.id === id))}
            onClickMainAction={onClickMainAction}
            toolbarHeader={toolbarHeader}
            textToolbarHeader={textToolbarHeader}
          />

          {toolbarOptions?.withIndex && <Divider css={dividerCss} />}

          <Box css={{ overflow: 'auto' }}>
            <Table>
              <SpreadsheetHeader
                headlines={headlines}
                withActions={!!cellActions?.length}
                withCheckbox={withCheckboxes}
                withIndex={!!toolbarOptions?.withIndex}
              />

              <TableBody>{tableContent}</TableBody>
            </Table>
          </Box>
          {toolbarOptions?.withPerPage && (
            <Box css={tableFooterCss}>
              <SpreadsheetPagination itemsCount={itemsCount ?? (data.length || 1)} />
            </Box>
          )}
        </TableContainer>
      </Paper>
    </Box>
  );
};
