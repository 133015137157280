import { useAuth } from '@8base-react/auth';

import { useUserByIdQuery } from 'shared/graphql';

export function useUserById({ id }: { id?: string | undefined | null }) {
  const { isAuthorized } = useAuth();

  const { data, loading, error } = useUserByIdQuery({
    skip: !isAuthorized,
    variables: { id },
  });

  return {
    user: data?.user || undefined,
    userId: data?.user?.id,
    loading: loading && !data,
    error,
  };
}
