import { css } from '@emotion/react';
import styled from '@emotion/styled';

// ContainerBase

export const ContainerBase = styled.div`
  --gap: 2rem;
  --padding: var(--gap);

  max-width: 100vw;
  width: 100%;
  padding: var(--padding);
`;

// ContainerDesktop

interface ContainerDesktopProps {
  hasSidebar?: boolean;
}

export const ContainerDesktop = styled(ContainerBase)<ContainerDesktopProps>`
  display: grid;
  gap: var(--gap);

  ${p =>
    p.hasSidebar &&
    css`
      grid-template-columns: 1fr 20rem;
    `}
`;

// ContainerMobile

export const ContainerMobile = styled(ContainerBase)`
  --gap: 1rem;

  display: flex;
  flex-direction: column;
`;

// Wrapper

export const Wrapper = styled.div`
  min-width: 0;
`;

// Content

export interface ContentProps {
  /** @default false */
  withMaxWidth?: boolean;
  /** @default `50rem`` */
  maxWidth?: string | number;

  /** @deprecated */
  withFullWidth?: boolean;
  /** @deprecated */
  withMinWidth?: boolean;
}

export const Content = styled.div<ContentProps>(props => {
  const base = css``;
  const maxWidth = props.maxWidth ?? '50rem';

  if (props.withMaxWidth || props.maxWidth) {
    return css`
      ${base};

      max-width: ${maxWidth};
      margin: 0 auto;
    `;
  }

  return base;
});

// Sidebar

export const Sidebar = styled.div`
  width: 100%;
`;
