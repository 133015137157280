import { HTMLAttributes, ReactNode } from 'react';

import { Container, ContainerProps, Text } from './PostCardIndicator.styles';

export interface PostCardIndicatorProps
  extends ContainerProps,
    Omit<HTMLAttributes<HTMLDivElement>, 'color'> {
  children: ReactNode;
}

/**
 * Indicator for a post card.
 * @ticket https://github.com/jebelapp/jebel/issues/1525
 */
export function PostCardIndicator(props: PostCardIndicatorProps) {
  return <Container {...props}>{props.children}</Container>;
}

/**
 * Text for a post card indicator.
 */
PostCardIndicator.Text = Text;
