import React, { useMemo } from 'react';
import { Box, MenuItem, SxProps } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  Preloader,
  Form,
  FormTextField,
  FormSelect,
  Button,
  createSnackMessage,
  SNACK_TYPES,
} from 'shared/components/ui';
import {
  useUserById,
  useRoleAddons,
  useUpdateUserRoleAddons,
  useSchoolConfiguration,
} from 'shared/hooks';
import { sendToSentry } from 'shared/utils/sentry';
import { formatUserName } from 'shared/utils/user';

const selectBoxCss: SxProps = {
  '& > *': {
    width: '100%',
  },
};

interface MemberEditRolesFormValues {
  userName: string;
  email: string;
  rolesAddonsIds: string[];
}

interface MemberEditRolesModalProps {
  memberId: string | undefined;
  onModalClose: () => void;
}

export const MemberEditRolesModal: React.FC<MemberEditRolesModalProps> = ({
  memberId,
  onModalClose,
}) => {
  const { user, loading: isUserLoading } = useUserById({ id: memberId });
  const { data: school, loading: schoolLoading } = useSchoolConfiguration();
  const { rolesAddons, isLoading: isRolesAddonsLoading } = useRoleAddons();
  const { updateUserRoles, isUpdating: isRolesUpdating } = useUpdateUserRoleAddons();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: MemberEditRolesFormValues = useMemo(() => {
    const roles = user?.rolesAddons?.items || [];
    const schoolRoles = roles.filter(role => role.school?.id === school?.id);

    return {
      userName: formatUserName(user),
      email: user?.email || 'Unknown',
      rolesAddonsIds: schoolRoles.map(role => role.id as string),
    };
  }, [user]);

  const onSubmit = React.useCallback(
    async (formValues: MemberEditRolesFormValues) => {
      try {
        await updateUserRoles(memberId || '', formValues.rolesAddonsIds);

        enqueueSnackbar('Roles updates successfully', {
          autoHideDuration: 5000,
          content: createSnackMessage(SNACK_TYPES.success),
        });
        onModalClose();
      } catch (e: any) {
        sendToSentry(`Error while updating roles! ${e}`);
        enqueueSnackbar('Error while updating roles!', {
          autoHideDuration: 5000,
          content: createSnackMessage(SNACK_TYPES.error),
        });
      }
    },
    [enqueueSnackbar, memberId, onModalClose, rolesAddons, updateUserRoles],
  );

  const isLoading = isUserLoading || schoolLoading || isRolesAddonsLoading;

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <Box pb={1}>
      <Form key={user?.id} initialValues={initialValues as any} onSubmit={onSubmit as any}>
        {({ dirty, isSubmitting, isValid, isValidating, handleSubmit, handleReset }) => {
          const isInputsDisabled = isLoading || isRolesUpdating || isSubmitting;
          const isActionsDisabled = isInputsDisabled || !isValid || isValidating || !dirty;

          return (
            <React.Fragment>
              <Box>
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'Name',
                    variant: 'outlined',
                    style: { width: '100%' },
                    disabled: true,
                  }}
                  fieldProps={{ name: 'userName' }}
                />
              </Box>

              <Box mt={2}>
                <FormTextField
                  inputProps={{
                    color: 'primary',
                    label: 'Email address',
                    variant: 'outlined',
                    style: { width: '100%' },
                    disabled: true,
                  }}
                  fieldProps={{ name: 'email' }}
                />
              </Box>

              <Box mt={2} sx={selectBoxCss}>
                <FormSelect
                  selectProps={{
                    label: 'Roles',
                    variant: 'outlined',
                    disabled: isInputsDisabled,
                    multiple: true,
                    children: rolesAddons.map(role => (
                      <MenuItem key={role.id} value={role.id || undefined}>
                        {role.name || role.role?.name || 'Unknown role'}
                      </MenuItem>
                    )),
                  }}
                  fieldProps={{ name: 'rolesAddonsIds' }}
                />
              </Box>

              <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
                <Button
                  loading={isRolesUpdating}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    handleReset();
                    onModalClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isActionsDisabled}
                  loading={isRolesUpdating}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Save changes
                </Button>
              </Box>
            </React.Fragment>
          );
        }}
      </Form>
    </Box>
  );
};
