import { HTMLAttributes, ReactNode } from 'react';
import { Container, Content, Sidebar } from './CarnivalLayout.styles';

export interface CarnivalLayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

/**
 * Layout for a content-sidebar arrangement.
 */
export function CarnivalLayout(props: CarnivalLayoutProps) {
  return <Container {...props} />;
}

/**
 * Wrapper for the main content of the layout.
 */
CarnivalLayout.Content = Content;

/**
 * Wrapper for the sidebar content of the layout.
 */
CarnivalLayout.Sidebar = Sidebar;
