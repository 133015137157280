import { Box, Grid } from '@material-ui/core';
import { FileValue } from '@8base-react/file-input';

import { FormTextField, AvatarField } from 'shared/components/ui';

export interface GroupDetailsFormValues {
  title?: string | null | undefined;
  description?: string | null | undefined;
  logo?: FileValue | null | undefined;
}

export function GroupDetailsForm() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <AvatarField name="logo" size="medium" label="Group Image" variant="rounded" />
      </Grid>

      <Grid item xs={12} md={9}>
        <Box display="flex" flexDirection="column" gridGap={16}>
          <FormTextField
            fieldProps={{ name: 'title' }}
            inputProps={{ label: 'Title', variant: 'outlined', fullWidth: true, required: true }}
          />

          <FormTextField
            fieldProps={{ name: 'description' }}
            inputProps={{ label: 'Description', variant: 'outlined', fullWidth: true }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
