import { useMemo } from 'react';

import { USER_AFFILIATIONS, type UserAffiliations, type UserStatuses } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { useMemberStatsQuery, UserFilter } from 'shared/graphql';
import { SELECT_OPTION_ALL } from 'shared/constants';

export interface MembersStats {
  totalCount: number;
  other: number;
  student: number;
  parent: number;
  alumni: number;
  staff: number;
}

export const useMembersStats = (status?: UserStatuses | typeof SELECT_OPTION_ALL) => {
  const filter = useMemo(() => {
    const filter = createFilterBuilder<UserFilter>();

    if (status && status !== SELECT_OPTION_ALL) {
      filter.and({ userStatus: { equals: status } });
    }

    return filter.build();
  }, [status]);

  const {
    data: response,
    loading,
    refetch,
  } = useMemberStatsQuery({
    variables: {
      filter,
    },
  });

  const membersStats = useMemo<MembersStats>(() => {
    const groups = response?.affiliations.groups ?? [];

    const affiliations: Record<UserAffiliations, number> = {
      [USER_AFFILIATIONS.alumni]: 0,
      [USER_AFFILIATIONS.parent]: 0,
      [USER_AFFILIATIONS.student]: 0,
      [USER_AFFILIATIONS.staff]: 0,
      [USER_AFFILIATIONS.other]: 0,
    };

    for (const group of groups) {
      if (!group.affiliation) {
        // Prevents to count users with no affiliation.
        continue;
      }

      const count = group.count ?? 0;

      affiliations[group.affiliation as UserAffiliations] = count;
    }

    const totalCount = response?.affiliations.totalCount ?? 0;

    const metrics: MembersStats = {
      ...affiliations,

      totalCount,
    };

    return metrics;
  }, [response]);

  const refetchStats = async () => {
    await refetch();
  };

  return {
    membersStats,
    loading,
    refetchStats,
  };
};
