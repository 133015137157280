import { useEffect } from 'react';
import { Campaign } from '@mui/icons-material';
import { Box, Button, Typography } from '@material-ui/core';
import { isObj, isURL } from '@jebel/utils';

import { useUpdateAdLike } from 'features/advertising/hooks/useUpdateAdLike';
import { useCreateImpressionMutation } from 'features/advertising/hooks/useCreateImpressionMutation';
import { useUpdateAdClicks } from 'features/advertising/hooks/useUpdateAdClicks';
import { MediaGallery, RichText } from 'shared/components/ui';
import { OrganizationAvatar, OrganizationNameLink } from 'shared/components/symbols';
import { AdItemFragment } from 'shared/graphql';
import { useIntersectionWatcher, useRelativeDate } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

import { AdLikeButton } from '../LikeButton';
import { PostCard } from '../PostCard';
import { PostCardAuthor } from '../PostCardAuthor';
import { PostCardIndicator } from '../PostCardIndicator';

export interface FeedAdProps {
  data: AdItemFragment;

  /**
   * Remove the border of the card.
   * @ticket https://github.com/jebelapp/jebel/issues/1525
   * @default false
   */
  borderless?: boolean;

  onClick?(): void;
}

export function FeedAd(props: FeedAdProps) {
  const creationDate = useRelativeDate(props.data.createdAt);
  const createImpression = useCreateImpressionMutation();
  const updateAdClicks = useUpdateAdClicks();

  const { ref, haveBeenShown } = useIntersectionWatcher<HTMLDivElement>();
  const { addLike, removeLike } = useUpdateAdLike();

  useEffect(() => {
    if (!props.data.id) {
      return;
    }

    if (haveBeenShown) {
      // Create the view on the client-side and calculate the rest on the server side
      // https://8base-dev.atlassian.net/browse/JEB-1412?focusedCommentId=41916
      createImpression(props.data.id);
    }
  }, [haveBeenShown]);

  const onClick = async () => {
    const link = props.data.callToActionLink;

    if (!props.data.id) {
      return;
    }

    await updateAdClicks(props.data.id);

    if (!link || !isURL(link)) {
      props.onClick?.();
      return;
    }

    try {
      const destination = new URL(link);

      if (destination.origin === window.location.origin) {
        // Open in the same tab instead of open a new window.
        window.location.assign(destination);
        return;
      }
    } catch (err) {
      recordError(err);
    }

    window.open(link, '_blank');
  };

  const handleLike = async () => {
    if (!props.data.id) {
      return;
    }

    await addLike(props.data.id);
  };

  const handleUnlike = async () => {
    if (!props.data.id) {
      return;
    }

    await removeLike(props.data.id);
  };

  return (
    <PostCard ref={ref} borderless={props.borderless}>
      <PostCardIndicator color="secondary">
        <Campaign />
        <PostCardIndicator.Text>Sponsored</PostCardIndicator.Text>
      </PostCardIndicator>

      <PostCardAuthor>
        <PostCardAuthor.Header>
          <OrganizationAvatar organization={props.data.sampaign?.advertising?.organization} />

          <PostCardAuthor.HeaderContent>
            <PostCardAuthor.HeaderName>
              <OrganizationNameLink organization={props.data.sampaign?.advertising?.organization} />
            </PostCardAuthor.HeaderName>

            <PostCardAuthor.CreationDate>{creationDate}</PostCardAuthor.CreationDate>
          </PostCardAuthor.HeaderContent>
        </PostCardAuthor.Header>
      </PostCardAuthor>

      <PostCard.Content>
        <RichText>
          <Typography>{props.data.primaryText}</Typography>
        </RichText>
      </PostCard.Content>

      {isObj(props.data.image) && <MediaGallery media={[props.data.image]} />}

      <PostCard.Footer>
        <AdLikeButton
          adId={props.data.id as string}
          onLikeAdd={handleLike}
          onReactionRemove={handleUnlike}
        />

        <Box display="flex" gridGap="1rem">
          <Button variant="contained" color="secondary" onClick={onClick} disableElevation>
            {props.data.callToActionText}
          </Button>
        </Box>
      </PostCard.Footer>
    </PostCard>
  );
}
