import styled from '@emotion/styled';
import { FormControl, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { ChangeEvent, useMemo, useState } from 'react';
import { useMembersStats } from '../hooks';
import { SelectOption, USER_STATUSES, UserStatuses } from '@jebel/constants';
import { SELECT_OPTION_ALL } from 'shared/constants';

const STAT_HEIGHT = '10rem';

const formatNumber = (num?: number | null | undefined) => {
  if (!num) return 0;

  const thousands = num / 1000;

  if (thousands > 3) {
    return `${thousands.toFixed(1)}K`;
  }

  if (thousands > 1) {
    return `${Math.trunc(thousands)},${num % 1000}`;
  }

  return num;
};

const FILTER_STATUS_OPTIONS: SelectOption[] = [
  {
    label: 'All',
    value: SELECT_OPTION_ALL,
  },
  {
    label: 'Active',
    value: USER_STATUSES.active,
  },
  {
    label: 'Inactive',
    value: USER_STATUSES.inactive,
  },
  {
    label: 'Invitation Sent',
    value: USER_STATUSES.invitationSent,
  },
  {
    label: 'Rejected',
    value: USER_STATUSES.rejected,
  },
];

export function MembersStats() {
  const [filterStatus, setFilterStatus] = useState(SELECT_OPTION_ALL);

  const { membersStats, loading } = useMembersStats(filterStatus as UserStatuses);

  const membersStatsCards = useMemo(() => {
    return [
      {
        label: 'Total Members',
        count: membersStats?.totalCount,
      },
      {
        label: 'Students',
        count: membersStats?.student,
      },
      {
        label: 'Parents',
        count: membersStats?.parent,
      },
      {
        label: 'Alumni',
        count: membersStats?.alumni,
      },
      {
        label: 'School Staff',
        count: membersStats?.staff,
      },
      {
        label: 'Other',
        count: membersStats?.other,
      },
    ];
  }, [membersStats]);

  const handleFilterStatusChange = (event: ChangeEvent<{ value: unknown }>) => {
    setFilterStatus(event.target.value as string);
  };

  return (
    <Container>
      <FormControl size="small">
        <Select
          variant="outlined"
          value={filterStatus}
          disabled={loading}
          onChange={handleFilterStatusChange}
        >
          {FILTER_STATUS_OPTIONS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {loading ? (
        <Content>
          {membersStatsCards.map(({ label }) => (
            <Skeleton key={label} variant="rect" height={STAT_HEIGHT} />
          ))}
        </Content>
      ) : (
        <Content>
          {membersStatsCards.map(({ label, count }) => (
            <MetricContainer key={label}>
              <MetricStat>{formatNumber(count)}</MetricStat>
              <MetricLabel>{label}</MetricLabel>
            </MetricContainer>
          ))}
        </Content>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${STAT_HEIGHT}, 1fr));
  gap: 1rem;
`;

const MetricContainer = styled(Paper)`
  height: ${STAT_HEIGHT};
  border-radius: 8px;
  background-color: ${p => p.theme.palette.background.paper};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const MetricStat = styled(Typography)`
  color: ${p => p.theme.palette.secondary.light};
  font-size: 63px;
`;

const MetricLabel = styled(Typography)``;
