import { Button, Chip, IconButton, Tooltip } from '@material-ui/core';
import { Redeem } from '@mui/icons-material';
import { useMemo } from 'react';

import { DiscountPresentationFragment } from 'shared/graphql';
import { MediaGallery } from 'shared/components/ui';
import { generateDiscountExpirationLabel, generateDiscountLabel } from 'shared/utils/discounts';
import { useRelativeDate } from 'shared/hooks';

import {
  AuthorOrganizationAvatar,
  AuthorSchoolAvatar,
  Buttons,
  Content,
  Container,
  Label,
  Title,
} from './HomeFeedCardDiscount.styles';
import { PostCardAuthor } from '../PostCardAuthor';
import { PostCard } from '../PostCard';
import { OrganizationNameLink, SchoolNameLink } from 'shared/components/symbols';

interface Props {
  data: DiscountPresentationFragment;

  /**
   * Remove the border of the card.
   * @ticket https://github.com/jebelapp/jebel/issues/1525
   * @default false
   */
  borderless?: boolean;

  onRedeem(discount: DiscountPresentationFragment): void;
}

export function HomeFeedCardDiscount(props: Props) {
  /**
   * Checks whether the current post is authored to the school.
   * https://8base-dev.atlassian.net/browse/JEB-1267?focusedCommentId=42005
   */
  const isOrganizationAuthored = Boolean(props.data.organization);

  const title = props.data.title;
  const images = props.data.images?.items ?? [];

  const creationDate = useRelativeDate(props.data.startDate);

  const expirationLabel = useMemo(() => {
    return generateDiscountExpirationLabel(props.data?.expirationDate);
  }, [props.data]);

  const label = useMemo(() => {
    return generateDiscountLabel(props.data?.type, props.data?.amountPercent);
  }, [props.data]);

  return (
    <PostCard borderless={props.borderless}>
      <PostCardAuthor>
        <PostCardAuthor.Header>
          {isOrganizationAuthored ? (
            <AuthorOrganizationAvatar organization={props.data.organization} />
          ) : (
            <AuthorSchoolAvatar />
          )}

          <PostCardAuthor.HeaderContent>
            <PostCardAuthor.HeaderName>
              {isOrganizationAuthored ? (
                <OrganizationNameLink organization={props.data.organization} />
              ) : (
                <SchoolNameLink />
              )}
            </PostCardAuthor.HeaderName>

            <PostCardAuthor.CreationDate>{creationDate}</PostCardAuthor.CreationDate>
          </PostCardAuthor.HeaderContent>
        </PostCardAuthor.Header>

        <PostCardAuthor.Actions>
          <Tooltip title="Redeem Offer">
            <IconButton color="primary" onClick={props.onRedeem}>
              <Redeem />
            </IconButton>
          </Tooltip>
        </PostCardAuthor.Actions>
      </PostCardAuthor>

      <Container>
        <Chip color="secondary" label={expirationLabel} />
      </Container>

      {images.length > 0 && <MediaGallery media={images} />}

      <Container>
        <Content>
          <Title>{title}</Title>
          <Label>{label}</Label>
        </Content>

        <Buttons>
          <Button
            color="secondary"
            variant="contained"
            disableElevation
            startIcon={<Redeem />}
            onClick={props.onRedeem}
          >
            Redeem Offer
          </Button>
        </Buttons>
      </Container>
    </PostCard>
  );
}
