import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// Container

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: var(--post-card-padding);
  gap: 1rem;
`;

// Header

export const Header = styled.div`
  --author-avatar-size: 2.5rem;

  display: flex;
  gap: 0.5rem;
  flex: 1;
`;

const headerAvatarCSS = css`
  min-width: var(--author-avatar-size);
  min-height: var(--author-avatar-size);
`;

// HeaderAvatar

export const HeaderAvatar = styled(Avatar)`
  ${headerAvatarCSS};
`;

// HeaderAvatarSkeleton

export const HeaderAvatarSkeleton = styled(Skeleton)`
  ${headerAvatarCSS};
`;

HeaderAvatarSkeleton.defaultProps = {
  variant: 'circle',
};

// HeaderContent

export const HeaderContent = styled.div`
  min-height: var(--author-avatar-size);
  display: flex;
  align-items: start;
  flex-direction: column;
  flex: 1;
`;

// HeaderName

export const HeaderName = styled.span`
  font-weight: bold;
  color: ${p => p.theme.palette.primary.main};
`;

// Actions

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

// CreationDate

export const CreationDate = styled.div`
  color: ${p => p.theme.palette.grey[500]};
`;
